<template>
    <v-card class="ma-4">
        <v-card-title>
            {{ title }}

            <v-divider
                    class="mx-6"
                    inset
                    vertical
            ></v-divider>

            <v-spacer></v-spacer>

            <v-toolbar-title v-if="$refs.calendar">
                {{ $refs.calendar.title }}
            </v-toolbar-title>
            <v-btn
                    color="grey darken-2"
                    fab
                    small
                    text
                    @click="prev"
            >
                <v-icon small>
                    mdi-chevron-left
                </v-icon>
            </v-btn>
            <v-btn
                    color="grey darken-2"
                    fab
                    small
                    text
                    @click="next"
            >
                <v-icon small>
                    mdi-chevron-right
                </v-icon>
            </v-btn>
            <v-btn
                    class="ml-4"
                    color="grey darken-2"
                    outlined
                    @click="setToday"
            >
                Today
            </v-btn>
        </v-card-title>

        <v-row class="fill-height">
            <v-col>

                <v-sheet height="600">
                    <v-calendar
                            ref="calendar"
                            v-model="focus"
                            :events="events"
                            :weekdays="weekday"
                            color="primary"
                            type="month"
                            @change="updateRange"
                            @click:event="showEvent"
                            @click:date="viewDay"

                    ></v-calendar>
                    <v-menu
                            v-model="selectedOpen"
                            :activator="selectedElement"
                            :close-on-content-click="false"
                            offset-x
                    >
                        <v-card
                                color="white lighten-4"
                                flat
                                max-width="400px"
                                min-width="400px"
                        >
                            <v-toolbar
                                    :color="selectedEvent.color??'blue'"
                                    dark
                            >
                                <v-toolbar-title v-html="'Service at '+focus"></v-toolbar-title>

                            </v-toolbar>
                            <v-alert
                                    v-if="Object.keys(errors).length>0"
                                    dense
                                    text
                                    type="error"
                            >
                                <h4 class="subtitle">Correct the following errors:</h4>
                                <ul>
                                    <li
                                            v-for="(error, index) in errors"
                                            :key="index"
                                    >{{ error.message || error }}
                                    </li>
                                </ul>
                            </v-alert>
                            <v-card-text>
                                <span v-html="selectedEvent.details"></span>
                                <v-row>
                                    <v-col cols="12">
                                        <v-select
                                                v-model="user"
                                                :items="users"
                                                :rules="[v => !!v || 'Please select customer']"
                                                hide-details
                                                item-text="name"
                                                item-value="id"
                                                label="Customer Name"
                                                required
                                                return-object
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-select
                                                v-model="category"
                                                :items="categories"
                                                :rules="[v => !!v || 'Please select category']"
                                                hide-details
                                                item-text="name"
                                                item-value="id"
                                                label="Category Name"
                                                required
                                                return-object
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-select
                                                v-model="type"
                                                :items="types"
                                                :rules="[v => !!v || 'Please select type']"
                                                hide-details
                                                item-text="name"
                                                item-value="id"
                                                label="Type Name"
                                                required
                                                return-object
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-select
                                                v-model="priority"
                                                :items="priorities"
                                                :rules="[v => !!v || 'Please select priority']"
                                                hide-details
                                                item-text="name"
                                                item-value="id"
                                                label="Priority level"
                                                required
                                                return-object
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field v-model="price" :name="price" label="Price"
                                                      min="0" type="number"
                                        ></v-text-field>

                                    </v-col>
                                    <v-col cols="6">
                                        <v-dialog
                                                ref="startDialog"
                                                v-model="startTimeDialog"
                                                :return-value.sync="startTime"
                                                :show-current="true"
                                                persistent
                                                width="290px"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                        v-model="startTime"
                                                        label="Start time"
                                                        v-bind="attrs"
                                                        v-on="on"

                                                ></v-text-field>

                                            </template>
                                            <v-time-picker
                                                    v-if="startTimeDialog"
                                                    v-model="startTime"
                                                    :max="endTime"
                                                    color="primary"
                                                    format="24hr"
                                                    header-color="primary"
                                                    scrollable
                                                    show-current
                                            >
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                        color="primary"
                                                        text
                                                        @click="startTimeDialog = false"
                                                >
                                                    Cancel
                                                </v-btn>
                                                <v-btn
                                                        color="primary"
                                                        text
                                                        @click="$refs.startDialog.save(startTime)"
                                                >
                                                    Ok
                                                </v-btn>
                                            </v-time-picker>
                                        </v-dialog>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-dialog
                                                ref="endDialog"
                                                v-model="endTimeDialog"
                                                :return-value.sync="endTime"
                                                :show-current="true"
                                                persistent
                                                width="290px"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                        v-model="endTime"
                                                        label="End time"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                ></v-text-field>

                                            </template>
                                            <v-time-picker
                                                    v-if="endTimeDialog"
                                                    v-model="endTime"
                                                    :min="startTime"
                                                    color="primary"
                                                    format="24hr"
                                                    header-color="primary"
                                                    scrollable
                                                    show-current
                                            >
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                        color="primary"
                                                        text
                                                        @click="endTimeDialog = false"
                                                >
                                                    Cancel
                                                </v-btn>
                                                <v-btn
                                                        color="primary"
                                                        text
                                                        @click="$refs.endDialog.save(endTime)"
                                                >
                                                    Ok
                                                </v-btn>
                                            </v-time-picker>
                                        </v-dialog>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-textarea v-model="note" :counter="1000" :rules="noteRules " clearable label="Notes" no-resize
                                                    rows="10"
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                        color="secondary"
                                        text
                                        @click="selectedOpen = false"
                                >
                                    Cancel
                                </v-btn>

                                <v-btn

                                        :loading="buttonLoading"
                                        class="ma-2"
                                        color="primary"
                                        @click="saveItem"
                                >Save
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-menu>
                </v-sheet>
            </v-col>
        </v-row>

    </v-card>
</template>

<script>
import networks from '../../services/networks';
import {roleCustomers, urlCreateService, urlJobCategories, urlServices, urlUpdateService, urlUsersBasedRole,} from "@/config";

export default {
    data: () => ({
        title: "Calendar Booking",
        loading: true,
        services: [],
        buttonLoader: 'buttonLoading',
        buttonLoading: false,
        isAllDays: false,
        id: 0,
        errors: [],

        weekday: [1, 2, 3, 4, 5, 6],
        focus: '',
        selectedEvent: {},
        selectedElement: null,
        selectedOpen: false,
        events: [],

        user: null,
        users: [],
        type: null,
        types: [],
        category: null,
        categories: [],
        jobCategories: [],
        priority: null,
        priorities: [{id: 1, name: "24 hour response"}, {id: 2, name: "4-8 hour response"}, {
            id: 3, name: "2-4 hour response"
        }, {id: 4, name: "Emergency response"},],

        price: null,
        note: null,
        noteRules: [
            v => (!v || v.length <= 1000) || 'Note must be less than 100 characters',
        ],
        startTimeDialog: false,
        endTimeDialog: false,
        startTime: null,
        endTime: null,

        isActive: true,


    }),

    created() {
        this.getUsersFromApi();
        this.getJobCategoriesFromApi();
        this.getServicesFromApi();
    },


    mounted() {
        this.$refs.calendar.checkChange()
    },
    methods: {

        getServicesFromApi() {
            this.loading = true;
            networks.fetchFromWeb(urlServices)
                .then(response => {
                    this.services = response.data;
                    this.loading = false;
                    this.updateRange();
                })
                .catch(error => {
                    alert(error);
                });


        },

        getJobCategoriesFromApi() {
            this.loading = true;
            networks.fetchFromWeb(urlJobCategories)
                .then(response => {
                    this.jobCategories = response.data;
                    this.categories = response.data.filter(jobCategory => jobCategory.isTypeCategory === true);
                    this.types = response.data.filter(jobCategory => jobCategory.isTypeCategory === false);
                    this.loading = false;
                })
                .catch(error => {
                    alert(error);
                });
        },

        getUsersFromApi() {
            this.loading = true;
          networks.fetchFromWeb(urlUsersBasedRole + roleCustomers)
                .then(response => {
                    this.users = response.data;
                    this.loading = false;
                })
                .catch(error => {
                    alert(error);
                });
        },

        viewDay(event) {

            if (!event.past) {


                this.clear();
                const checkAvailableDate = (date) => {
                    return this.services.filter(availableInstall => {
                        return availableInstall.startDate == date;
                    });
                };

                let available = checkAvailableDate(event.date);
                if (available.length > 0) {
                    event['id'] = available[0].id;
                    event['price'] = available[0].price;
                    event['isActive'] = available[0].isActive;
                    event['name'] = (available[0].price !== null) ? "Extra Money  = " + available[0].price : "Free Install";
                    event['color'] = (available[0].isActive) ? ((available[0].price !== null) ? 'orange' : 'green') : 'red';

                }
                if (this.selectedOpen) {
                    this.selectedOpen = false;
                    requestAnimationFrame(() => requestAnimationFrame(() => this.openDialogAvailable(event.nativeEvent, event)))
                } else {
                    this.openDialogAvailable(event.nativeEvent, event);
                }
            }


            event.nativeEvent.stopPropagation();

        },

        setToday() {
            this.focus = ''
        },

        prev() {
            this.$refs.calendar.prev()
        },

        next() {
            this.$refs.calendar.next()
        },

        showEvent({nativeEvent, event}) {

            if (event.start >= new Date().toISOString().slice(0, 10)) {
                this.clear();
                event['date'] = event.start;

                if (this.selectedOpen) {
                    this.selectedOpen = false;
                    requestAnimationFrame(() => requestAnimationFrame(() => this.openDialogAvailable(nativeEvent, event)))
                } else {
                    this.openDialogAvailable(nativeEvent, event);
                }
            }
            nativeEvent.stopPropagation();
        },

        openDialogAvailable(nativeEvent, event) {
            this.selectedEvent = event;
            this.selectedElement = nativeEvent.target;
            this.id = event.id;
            this.price = event.price;
            this.isActive = event.isActive ?? true;
            this.user = event.user;
            this.type = event.type;
            this.category = event.category;
            this.priority = event.priority;
            this.bookingDate = event.bookingDate;
            this.startTime = event.startTime;
            this.endTime = event.endTime;
            this.note = event.note;
            this.focus = event.date;

            requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true));
        },

        updateRange() {
            const events = [];
            this.services.forEach(available => {

                events.push({
                    id: available.id,
                    name: this.getCustomerName(available.userID),
                    start: available.bookingDate,
                    end: available.bookingDate,
                    color: 'green',
                    timed: false,
                    price: available.price ?? null,
                    isActive: available.isActive ?? true,
                    user: {id: available.userID},
                    type: {id: available.typeID},
                    category: {id: available.categoryID},
                    priority: {id: available.priority},
                    bookingDate: available.bookingDate,
                    startTime: available.startTime,
                    endTime: available.endTime,
                    note: available.note,
                });

            });
            this.events = events;
        },

        saveItem() {
            let data = {
                userID: this.user.id,
                typeID: this.type.id,
                categoryID: this.category.id,
                priority: this.priority.id,
                price: this.price,
                bookingDate: this.focus,
                startTime: this.startTime,
                endTime: this.endTime,
                note: this.note,
                isActive: this.isActive,
            };

            if (this.id > 0)
                networks.fetchFromWeb(urlUpdateService + this.id, 2, data)
                    .then(response => {

                        if (response.status == 200) {
                            this.clear();
                            this.dialogAddItem = false;
                            alert("the data has been edited successfully");
                            this.getServicesFromApi();
                        }


                    })
                    .catch(error => {
                        this.errors = error.response.data.errors || error.response.data.error;
                        this.buttonLoading = false;
                    });
            else {
                networks.fetchFromWeb(urlCreateService, 1, data)
                    .then(response => {

                        if (response.status == 200) {
                            this.clear();
                            this.dialogAddItem = false;
                            alert("the data has been added successfully");
                            this.getServicesFromApi();
                        }
                    })
                    .catch(error => {
                        this.errors = error.response.data.errors || error.response.data.error;
                        this.buttonLoading = false;

                    });
            }
        },

        clear() {
            this.isAllDays = false;
            this.selectedOpen = false;

            this.user = null;
            this.type = null;
            this.category = null;
            this.priority = null;
            this.startTime = null;
            this.endTime = null;
            this.note = null;
            this.price = null;
            this.id = 0;
            this.isActive = true;
            this.valid = true;
            this.errors = [];
            this.buttonLoading = false;
        },

        getJobCategoryName(id) {
            let parent = this.jobCategories.filter(c => c.id == id);
            return parent[0]?.name;
        },

        getCustomerName(id) {
            let parent = this.users.filter(c => c.id == id);
            return parent[0]?.name;
        },
    },
}
</script>



